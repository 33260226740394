<template>
    <component
        :is="element"
        :to="uri || ''"
        class="upcoming"
    >
        <h4
            v-if="$slots.title"
            class="upcoming__title"
        >
            <slot name="title" />
        </h4>
        <p class="upcoming__text"><slot /></p>
        <p
            v-if="$slots.meta"
            class="upcoming__meta"
        >
            <slot name="meta" />
        </p>
    </component>
</template>

<script setup lang="ts">
type Props = {
    uri?: string | null;
};

const props = defineProps<Props>();

const { checkIsExternal } = useUri();
const element = computed(() => {
    if (!props.uri) {
        return 'article';
    }
    if (checkIsExternal(props.uri)) {
        return 'a';
    }
    return resolveComponent('NuxtLink');
});

</script>

<style lang="less" src="./BaseUpcoming.less" />
