<template>
    <main class="layout__main layout__main--no-top-padding">
        <BaseHero
            :image="featureFlag('center-hero') ? home?.squareImage?.[0] : home?.headerImage?.[0] "
            :placeholder="pending"
        >
            <template #text>
                <!-- eslint-disable vue/no-v-html -->
                <h2
                    v-if="home && home.headerText"
                    data-allow-anchor
                    v-html="home.headerText"
                />
                <!-- eslint-enable vue/no-v-html -->
            </template>

            <template #banner>
                <BaseUpcoming
                    v-if="home && home.highlight.length && featureFlag('hero-highlight')"
                    :uri="home.highlight[0].entry.length ? home.highlight[0].entry[0].uri : ''"
                >
                    <template #title>{{ home.highlight[0].title }}</template>
                    <template #default>{{ home.highlight[0].text }}</template>
                    <template
                        v-if="home.highlight[0].entry.length"
                        #meta
                    >
                        {{ formatHighlightDate(home.highlight[0].entry[0]) }}
                    </template>
                </BaseUpcoming>
            </template>
        </BaseHero>

        <article
            class="bleed"
        >
            <BasePills
                v-if="home && home.selectedTopics?.length && featureFlag('home-selected-topics')"
                :pills="home.selectedTopics[0].topics"
            >
                <template #title>
                    {{ home.selectedTopics[0].title }}
                </template>
                <template #description>
                    {{ home.selectedTopics[0].description }}
                </template>
            </BasePills>
            <BasePills
                v-if="home?.selectedSearches && featureFlag('home-selected-searches')"
                :pills="home?.selectedSearches?.[0]?.searches"
                :pills-class="`pill--square pill--largest`"
                :centered="true"
                :compact="true"
            >
                <template #title>
                    <TheSearchForm
                        :placeholder="home.selectedSearches[0]?.searchPlaceholder"
                        :class="`search-form--largest`"
                        :in-navigation="false"
                    />
                </template>
                <template #description>
                    {{ home?.selectedSearches?.[0]?.searches?.length ? home.selectedSearches[0]?.searchDescription : "" }}
                </template>
            </BasePills>

            <BaseSection
                v-if="bot && featureFlag('home-chatbot')"
                class="container-narrow"
            >
                <FlexibleSectionsBlock
                    v-if="bot"
                    :block="bot"
                />
            </BaseSection>

            <BaseSection
                v-if="home?.linksList && home.linksList?.length && featureFlag('home-links-list')"
                class="container-narrow"
            >
                <BaseQuicklinkList
                    v-if="home?.linksList && home.linksList?.length && featureFlag('home-links-list')"
                    :links="home.linksList"
                />
            </BaseSection>

            <FlexibleSections
                v-if="featureFlag('home-flex-sections')"
                :sections="home?.sections && Array.isArray(home.sections) ? home.sections : []"
                :placeholder="pending ? 'overview' : undefined"
                data-wide
            />
        </article>
    </main>
</template>

<script setup lang="ts">
import HomeQuery from '~/graphql/queries/Home.graphql';

const { activeSite, featureFlag } = useMultisite();
const { variables } = useCraftVariables();
variables.uri = undefined;

const response = await useLazyAsyncQuery({
    query: HomeQuery,
    variables
});

const {
    craftEntry: home,
    pending,
} = usePageQuery(
    response,
    { entryKey: 'home', activeSite }
);

function formatHighlightDate(highlight: any) {
    if (!highlight) {
        return;
    }

    // Prefer dateRange
    if (highlight.dateRange) {
        return useDate().formatDateRange(highlight.dateRange.start, highlight.dateRange.end);
    }

    // Fall back to postdate
    return useDate().formatDateRange(highlight.postDate, highlight.postDate);
}

const bot = computed(() => toValue(home)?.chatBotSelector);
</script>
