<template>
    <header
        class="hero"
        :class="featureFlag('center-hero') ? 'hero--center' : ''"
    >
        <transition name="fade">
            <template v-if="image?.large">
                <img
                    :src="image.large"
                    :srcset="getSrcSet(image, 'header')"
                    sizes="100vw"
                    alt=""
                    class="hero__background"
                >
            </template>
            <template v-else-if="placeholder">
                <content-placeholders-img class="hero__background" />
            </template>
        </transition>

        <div
            v-if="$slots.text || placeholder"
            class="hero__text"
        >
            <slot name="text">
                <content-placeholders-text :lines="2" />
            </slot>
        </div>

        <div
            v-if="$slots.banner"
            class="hero__banner"
        >
            <slot name="banner" />
        </div>
    </header>
</template>

<script setup>
import useSrcSets from '~/utils/srcset';

const {featureFlag} = useMultisite();
const {getSrcSet} = useSrcSets();

defineProps({
    placeholder: {
        type: Boolean,
        required: false,
        default: false
    },
    image: {
        type: Object,
        required: false,
        default: null
    }
});
</script>
<style lang="less" src="./BaseHero.less" />
