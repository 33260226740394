<template>
    <section
        class="pills"
        :class="{ 'pills--centered': centered }"
    >
        <div class="pills__wrapper">
            <h2
                v-if="$slots.title"
                class="pills__title"
            >
                <slot name="title" />
            </h2>
            <p
                v-if="$slots.description"
                class="pills__description"
                :class="{ 'pills__description--compact': compact }"
            >
                <slot name="description" />
            </p>

            <div
                v-if="pills.length"
                class="pills__pills"
            >
                <BasePill
                    v-for="topic in pills"
                    :key="topic.uri"
                    :class="pillsClass"
                    :element="NuxtLink"
                    :to="`/${topic.uri}`"
                >
                    {{ topic.title }}
                </BasePill>
            </div>
        </div>
    </section>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
</script>

<script>
export default {
    props: {
        pills: {
            type: Array,
            required: false,
            default: () => []
        },
        pillsClass: {
            type: String,
            required: false,
            default: 'pill--square'
        },
        centered: {
            type: Boolean,
            required: false,
            default: false
        },
        compact: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="less">
.pills {
    &:not(.pills--wide) {
        .grid-container(true, var(--grid-maxWidth-page));
    }
}

.pills__wrapper {
    display: grid;
    gap: var(--spacing-md);
    margin-top: var(--spacing-section);

    @media @q-lg-min {
        max-width: 66%;
    }
}

.pills--centered .pills__wrapper {
    margin: var(--spacing-section) auto 0;
    min-width: 50%;
    text-align: center;

    .pills__pills {
        justify-content: center;
    }
}

.pills__title {
    .typography-h3;
    margin: 0;
}

.pills__description {
    margin: 0;

    &.pills__description--compact {
        margin-bottom: calc(-1 * var(--spacing-md));
        margin-top: var(--spacing-md);
    }
}

.pills__pills {
    display: flex;
    --gap: calc(var(--spacing-sm) / 2);
    flex-wrap: wrap;
    margin: calc(var(--spacing-md) - var(--gap)) calc(var(--gap) * -1);

    .pill {
        margin: var(--gap);
    }
}

.pills--wide {
    .pills__wrapper {
        max-width: none;
        margin-top: 0;
    }
}
</style>
